import _ from 'lodash';

export default {
    name: 'Icons',
    data(){
        return {
            icons: [
                {
                    title: "Pictos",
                    icons: [
                        {icon: "Activites", viewBox:"0 0 48 48"},
                        {icon: "Adresse", viewBox:"0 0 24 24"},
                        {icon: "Adresse2", viewBox:"0 0 24 24"},
                        {icon: "Attention", viewBox:"0 0 24 24"},
                        {icon: "Balle", viewBox:"0 0 48 48"},
                        {icon: "BigScreen", viewBox:"0 0 24 24"},
                        {icon: "BtnFlecheCarre", viewBox:"0 0 24 24"},
                        {icon: "Building", viewBox:"0 0 24 24"},
                        {icon: "CAChat", viewBox:"0 0 19 18"},
                        {icon: "CAPas", viewBox:"0 0 19 24"},
                        {icon: "Calandar", viewBox:"0 0 24 24"},
                        {icon: "CalandarDays", viewBox:"0 0 24 24"},
                        {icon: "Canvas0", viewBox:"0 0 24 24"},
                        {icon: "Canvas1", viewBox:"0 0 24 24"},
                        {icon: "CentreAide", viewBox:"0 0 27 25"},
                        {icon: "Check", viewBox:"0 0 48 48"},
                        {icon: "Doc", viewBox:"0 0 24 24"},
                        {icon: "Entreprise", viewBox:"0 0 24 24"},
                        {icon: "Factory", viewBox:"0 0 48 48"},
                        {icon: "FavParcelles", viewBox:"0 0 24 24"},
                        {icon: "FavRecherches", viewBox:"0 0 24 24"},
                        {icon: "FavSites", viewBox:"0 0 24 24"},
                        {icon: "FavSurfaces", viewBox:"0 0 24 24"},
                        {icon: "Filter", viewBox:"0 0 48 48"},
                        {icon: "Filter2", viewBox:"0 0 14 14"},
                        {icon: "FullScreen", viewBox:"0 0 24 24"},
                        {icon: "FullScreenOff", viewBox:"0 0 24 24"},
                        {icon: "Gisement", viewBox:"0 0 20 20", width: 20, height: 20},
                        {icon: "Hot", viewBox:"0 0 48 48"},
                        {icon: "Image", viewBox:"0 0 24 24"},
                        {icon: "Indicator", viewBox:"0 0 22 22"},
                        {icon: "Info", viewBox:"0 0 512 512"},
                        {icon: "Liste", viewBox:"0 0 24 24"},
                        {icon: "Loading", viewBox:"0 0 100 100"},
                        {icon: "Mail", viewBox:"0 0 48 48"},
                        {icon: "Map", viewBox:"0 0 48 48"},
                        {icon: "Market", viewBox:"0 0 48 48"},
                        {icon: "Menu", viewBox:"0 0 48 48"},
                        {icon: "MinusCircle", viewBox:"0 0 512 512"},
                        {icon: "Oeil", viewBox:"0 0 16 16"},
                        {icon: "Output", viewBox:"0 0 48 48"},
                        {icon: "Outils", viewBox:"0 0 24 24"},
                        {icon: "Parcelle", viewBox:"0 0 24 24"},
                        {icon: "Parcelle2", viewBox:"0 0 24 29"},
                        {icon: "ParcellesFilter", viewBox:"0 0 24 24"},
                        {icon: "ParcellesX2", viewBox:"0 0 24 24"},
                        {icon: "Person", viewBox:"0 0 24 24"},
                        {icon: "Phone", viewBox:"0 0 48 48"},
                        {icon: "PhoneFull", viewBox:"0 0 48 48"},
                        {icon: "Pin", viewBox:"0 0 24 24"},
                        {icon: "PotentielMetha", viewBox:"0 0 23 23"},
                        {icon: "Regle", viewBox:"0 0 24 24"},
                        {icon: "Screen", viewBox:"0 0 448 448"},
                        {icon: "Secteur", viewBox:"0 0 24 24"},
                        {icon: "Setting", viewBox:"0 0 48 48"},
                        {icon: "SitesDispos", viewBox:"0 0 24 24"},
                        {icon: "StarCheckbox", viewBox:"0 0 48 48"},
                        {icon: "StarEmpty", viewBox:"0 0 48 48"},
                        {icon: "StarFull", viewBox:"0 0 48 48"},
                        {icon: "TonnesDispos", viewBox:"0 0 24 24"},
                        {icon: "TooHigh", viewBox:"0 0 10 10"},
                        {icon: "Warning", viewBox:"0 0 576 576"},
                        {icon: "Waste", viewBox:"-2 0 52 46"},
                        {icon: "Xls", viewBox:"0 0 24 24"},
                        {icon: "PinNew", viewBox:"0 0 24 24"},
                        {icon: "Rectangle", viewBox:"0 0 12 12"},
                        {icon: "Square", viewBox:"0 0 24 24"},
                        {icon: "Circle", viewBox:"0 0 24 24"},
                        {icon: "A", viewBox:"0 0 24 24"},
                        {icon: "SquareZebra", viewBox:"0 0 12 12"},
                        {icon: "Truck", viewBox:"0 0 24 24"},
                        {icon: "LigneBrisee", viewBox:"0 0 24 24"},
                        {icon: "Radius", viewBox:"0 0 12 12"},
                    ]
                },
                {
                    title: "Action",
                    icons: [
                        {icon: "Action", viewBox:"0 0 24 24"},
                        {icon: "Handle", viewBox:"0 0 5 8"},
                        {icon: "Pencil", viewBox:"0 0 48 48"},
                        {icon: "Pencil2", viewBox:"0 0 24 24"},
                        {icon: "Pencil3", viewBox:"0 0 24 24"},
                        {icon: "Pencil4", viewBox:"0 0 22 24"},
                        {icon: "Refresh", viewBox:"0 0 24 24"},
                        {icon: "Save", viewBox:"0 0 48 48"},
                        {icon: "Save2", viewBox:"0 0 24 24"},
                        {icon: "Search", viewBox:"0 0 48 48"},
                        {icon: "Search2", viewBox:"0 0 16 16"},
                        {icon: "Trash", viewBox:"0 0 48 48"},
                        {icon: "Trash2", viewBox:"0 0 24 24"},
                        {icon: "Download", viewBox:"0 0 48 48"},
                        {icon: "Download2", viewBox:"0 0 24 24"},
                        {icon: "Erase", viewBox:"0 0 12 13"},
                    ]
                },
                {
                    title: "Interface",
                    icons: [
                        {icon: "Close2", viewBox:"0 0 24 24"},
                        {icon: "Arrow", viewBox:"0 0 48 48"},
                        {icon: "Chevron", viewBox:"0 0 24 24"},
                        {icon: "Chevron2", viewBox:"0 0 10 10"},
                        {icon: "Down", viewBox:"0 0 24 24"},
                        {icon: "Plus", viewBox:"0 0 24 24"},
                    ]
                },
                {
                    title: "Formulaire",
                    icons: [
                        {icon: "Switch", viewBox:"0 0 30 24", width: 30},
                        {icon: "Selected", viewBox:"0 0 18 18", width: 18, height: 18},
                        {icon: "SelectedDark", viewBox:"0 0 18 18", width: 18, height: 18},
                    ]
                },
                {
                    title: "Divers",
                    icons: [
                        {icon: "Logo", viewBox:"0 0 85 33", width: 85, height: 33},
                        {icon: "SkypeEmpty", viewBox:"0 0 48 48", width: 48, height: 48},
                    ]
                }
            ]
        }
    },
    methods: {
        getIconHtml(icon) {
            return `<icon-base viewBox="${icon.viewBox}" width="${icon.width ?? 24}" height="${icon.height ?? 24}"><Icon${icon.icon} /></icon-base>`;
        },
        copyIcon(icon) {
            navigator.clipboard.writeText(this.getIconHtml(icon));
            alert(this.getIconHtml(icon) + "\n Copied to clipboard");
        },
        getIcons(icons) {
            let _icons = _.cloneDeep(icons);
            _icons.sort((a, b) => a.icon.localeCompare(b.icon));
            return _icons;
        }
    }
}