var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('Show_Component',{attrs:{"path":"common/ui/card","currentComponent":_vm.composant_card,"params":{
            close: () => this.affiche('close')
        },"slot_content":"\n            <h1 class='mt-0 mb-2'>Ceci est une Card</h1><p>Le contenu est placé dans un slot<br/>et il est possible d'activer une croix de fermeture<br/>Le titre n'est pas géré par la card pour laisser la liberté de l'adapter au style souhaité</p>\n        ","notes":"\n            close: si une fonction est fournie, la croix de fermeture est affichée et la fonction est appelée lors du click sur la croix\n\n            Le contenu est placé dans un slot et le contenu n'est pas stylé par la card\n            "}}),_c('Show_Component',{attrs:{"path":"common/ui/modale","currentComponent":_vm.composant_modale,"btn_switch":true,"btn_switch_label":"Afficher une modale complète","params":{
            titre: 'Ceci est une Modale Complète',
            picto: 'Check',
            vw: 48,
            vh: 48,
            closeBtn: true,
            auto: true,
        },"slot_content":"\n            <h1 class='mt-0 mb-2'>Titre interne au slot (h1)</h1>\n            <h2 class='mt-0 mb-2'>Titre interne au slot (h2)</h2>\n            <h3 class='mt-0 mb-2'>Titre interne au slot (h3)</h3>\n            <h4 class='mt-0 mb-2'>Titre interne au slot (h4)</h4>\n            <p>Le contenu est placé dans un slot<br/>et il est possible d'activer une croix de fermeture et un picto</p>\n            <ul>\n                <li>Liste</li>\n                <li>Liste</li>\n                <li>Liste</li>\n            </ul>\n        ","notes":"\n            closeBtn: si true, une croix de fermeture est affichée et un événement @close est émis lors du click sur la croix\n            picto: si un nom de picto est fourni, un picto est affiché\n            titre: si un titre est fourni, il est affiché\n            auto: si true, la modale est affichée de façon autonome. Elle peut être placée n'importe où dans un composant et son affichage être conditionné par un v-if\n            Un slot est disponible pour afficher un contenu personnalisé\n            -  \n            Exemple de placement dans un composant :\n            <Modale v-if='condition' :auto='true' :titre='' :closeBtn='true' @close='function' picto='Check' :vw='48' :vh='48' />\n            "}}),_c('Show_Component',{attrs:{"path":"common/ui/modale","currentComponent":_vm.composant_modale,"btn_switch":true,"btn_switch_label":"Afficher une modale simple","params":{
            titre: 'Ceci est une Modale Simple',
            closeBtn: false,
            auto: true,
        },"notes":"\n            closeBtn: si true, une croix de fermeture est affichée et un événement @close est émis lors du click sur la croix\n            picto: si un nom de picto est fourni, un picto est affiché\n            titre: si un titre est fourni, il est affiché\n            auto: si true, la modale est affichée de façon autonome. Elle peut être placée n'importe où dans un composant et son affichage être conditionné par un v-if\n            Un slot est disponible pour afficher un contenu personnalisé\n            "}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }