var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('Show_Component',{attrs:{"path":"common/forms/Input.vue","currentComponent":_vm.composant_input,"params":{
			field: 'nom',
			label: 'Input sans label',
			haut: true,
			no_mini_label: true
		},"notes":"\n\t\t\tAjouter la class haut pour avoir un champ de taille standard\n\t\t\tAjouter la class no_mini_label pour avoir un champ sans label intégré\n\t\t\tPour un champ date : <Input type='date' v-model='date' class='haut no_mini_label' pictoRight='IconCalandarDays' />\n\t\t"}}),_c('Show_Component',{attrs:{"path":"common/forms/Input.vue","currentComponent":_vm.composant_input,"params":{
			field: 'nom',
			label: 'Input avec label',
			haut: true
		},"notes":"\n\t\t\tAjouter la class haut pour avoir un champ de taille standard\n\t\t\tAjouter la class no_mini_label pour avoir un champ sans label intégré\n\t\t\tPour un champ date : <Input type='date' v-model='date' class='haut no_mini_label' pictoRight='IconCalandarDays' />\n\t\t"}}),_c('Show_Component',{attrs:{"path":"common/forms/Select.vue","currentComponent":_vm.composant_select,"params":{
			w100: true,
			label: 'Famille',
			options: [
				{ label: 'Famille 1', value: 'famille_1'},
				{ label: 'Famille 2', value: 'famille_2'},
				{ label: 'Famille 3', value: 'famille_3'}
			]
		},"notes":"\n\t\t\tLes options doivent intégrer une clé 'label' et une clé 'value'\n\t\t\tAjouter la class w100 pour que la zone déroulante soit de la largeur de la zone d'input\n\t\t\tautoEmptyAfterSelect: true pour vider le champ après sélection\n\t\t"}}),_c('Show_Component',{attrs:{"path":"common/forms/Select.vue","currentComponent":_vm.composant_select,"params":{
			w100: true,
			label: 'Famille',
			empty: null,
			_empty_label: 'Sélectionner une famille',
			options: [
				{ label: 'Famille 1', value: 'famille_1'},
				{ label: 'Famille 2', value: 'famille_2'},
				{ label: 'Famille 3', value: 'famille_3'}
			]
		},"notes":"\n\t\t\tLes options doivent intégrer une clé 'label' et une clé 'value'\n\t\t\tAjouter la class w100 pour que la zone déroulante soit de la largeur de la zone d'input\n\t\t\tautoEmptyAfterSelect: true pour vider le champ après sélection\n\t\t"}}),_c('Show_Component',{attrs:{"path":"common/forms/Select.vue","currentComponent":_vm.composant_select,"params":{
			w100: true,
			label: 'Famille',
			picto: 'IconFactory',
			viewBox: '0 0 48 48',
			empty: null,
			_empty_label: 'Sélectionner une famille',
			options: [
				{ label: 'Famille 1', value: 'famille_1'},
				{ label: 'Famille 2', value: 'famille_2'},
				{ label: 'Famille 3', value: 'famille_3'}
			],
			vmodel         : 'famille_1'
		},"notes":"\n\t\t\tLes options doivent intégrer une clé 'label' et une clé 'value'\n\t\t\tAjouter la class w100 pour que la zone déroulante soit de la largeur de la zone d'input\n\t\t\tautoEmptyAfterSelect: true pour vider le champ après sélection\n\t\t"}}),_c('Show_Component',{attrs:{"path":"common/forms/Select.vue","currentComponent":_vm.composant_select,"params":{
			empty: null,
			empty_label: 'Sélectionner une famille',
			options: [
				{ label: 'Famille 1', value: 'famille_1'},
				{ label: 'Famille 2', value: 'famille_2'},
				{ label: 'Famille 3', value: 'famille_3'}
			]
		},"notes":"\n\t\t\tLes options doivent intégrer une clé 'label' et une clé 'value'\n\t\t\tAjouter la class w100 pour que la zone déroulante soit de la largeur de la zone d'input\n\t\t"}}),_c('Show_Component',{attrs:{"path":"common/forms/Select.vue","currentComponent":_vm.composant_select,"params":{
			w100: true,
			label: 'Avec recherche interne',
			empty: null,
			selfsearch: true,
			options: [
				{ label: 'Famille 01', value: 'famille_1'},
				{ label: 'Famille 02', value: 'famille_2'},
				{ label: 'Famille 03', value: 'famille_3'},
				{ label: 'Famille 04', value: 'famille_4'},
				{ label: 'Famille 05', value: 'famille_5'},
				{ label: 'Famille 06', value: 'famille_6'},
				{ label: 'Famille 07', value: 'famille_7'},
				{ label: 'Famille 08', value: 'famille_8'},
				{ label: 'Famille 09', value: 'famille_9'},
				{ label: 'Famille 10', value: 'famille_10'},
				{ label: 'Famille 11', value: 'famille_11'},
				{ label: 'Famille 12', value: 'famille_12'},
				{ label: 'Famille 13', value: 'famille_13'},
				{ label: 'Famille 14', value: 'famille_14'},
				{ label: 'Famille 15', value: 'famille_15'},
			]
		},"notes":"\n\t\t\tLes options doivent intégrer une clé 'label' et une clé 'value'\n\t\t\tAjouter la class w100 pour que la zone déroulante soit de la largeur de la zone d'input\n\t\t"}}),_c('Show_Component',{attrs:{"path":"common/ui/statuses_selector.vue","currentComponent":_vm.composant_statuts,"params":{
			element: {
				identifiant: '1234567890',
				current_status: 'active'
			},
			field : 'current_status',
			target: 'companies'
		},"notes":"\n\t\t\tL'affichage du bouton de paramétrage des statuts est conditionné par la valeur du getter 'canManageStatuses'\n\t\t\tTarget peux prendre les valeurs : 'companies', 'parcels'. La liste des statuts affichée dépend de la valeur de target.\n\t\t\tField permet de préciser le champ de element sur lequel chercher la valeur. Par défaut, c'est 'current_status'.\n\t\t"}}),_c('Show_Component',{attrs:{"path":"common/forms/ContentEditable.vue","currentComponent":_vm.composant_contenteditable,"params":{
			always_editable: true,
			placeholder    : 'Votre contenu personnalisé ici ...',
			placeholder_fix: true,
			vmodel         : 'Content Editable avec un label qui reste affiché dans le champ'
		},"notes":"\n\t\t\talways_editable: true pour garder active visuellement la zone éditable. Sinon, la zone ne s'active que lorsqu'on clique dedans\n\t\t\tplaceholder_fix: true pour laisser le placeholder en haut de la zone\n\t\t"}}),_c('Show_Component',{attrs:{"path":"common/forms/ContentEditable.vue","currentComponent":_vm.composant_contenteditable,"params":{
			always_editable: true,
			placeholder    : 'Votre contenu personnalisé ici ...',
			vmodel         : 'Content Editable avec un label qui se masque'
		},"notes":"always_editable: true pour garder active visuellement la zone éditable. Sinon, la zone ne s'active que lorsqu'on clique dedans"}}),_c('Show_Component',{attrs:{"path":"common/forms/ContentEditable.vue","currentComponent":_vm.composant_contenteditable,"params":{
			always_editable: false,
			placeholder    : 'Votre contenu personnalisé ici ...',
			data           : 'Content Editable sans bord',
			activable_on_click: true
		},"notes":"always_editable: true pour garder active visuellement la zone éditable. Sinon, la zone ne s'active que lorsqu'on clique dedans\n\t\tAttention, si le parent est en display: flex, ça peut poser problème"}}),_c('Show_Component',{attrs:{"path":"common/forms/ContentEditable","currentComponent":_vm.composant_contenteditable,"params":{
			always_editable   : true,
			placeholder       : 'Votre contenu personnalisé ici ...',
			data              : 'ContentEditable inline',
			inline: true
		},"notes":"always_editable: true pour garder active visuellement la zone éditable. Sinon, la zone ne s'active que lorsqu'on clique dedans\n\t\tAttention, si le parent est en display: flex, ça peut poser problème"}}),_c('Show_Component',{attrs:{"path":"common/forms/Phone.vue","currentComponent":_vm.composant_phone,"params":{
			haut: true,
			field: 'phone',
			label: 'Téléphone'
		},"notes":"\n\t\t\tAjouter la class haut pour avoir un champ de taille standard\n\t\t"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }