var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"buttons"},[_c('Show_Component',{attrs:{"path":"common/ui/loading_btn","currentComponent":_vm.composant_loading_btn,"slot_content":"Bouton vert par défaut, forcé aligné au centre","params":{
                class: 'center'
			},"notes":"Ajouter la classe 'center' pour que le bouton soit aligné au centre.\n            Ajouter la class 'full' pour que le bouton prenne toute la largeur de la page si ça n'est pas le cas par défaut"}}),_c('Show_Component',{attrs:{"path":"common/ui/loading_btn","currentComponent":_vm.composant_loading_btn,"slot_content":"Bouton taille auto. Class : inline","params":{
                class: 'inline'
			},"notes":"Ajouter la classe 'inline' pour que le bouton s'ajuste à son contenu"}}),_c('Show_Component',{attrs:{"path":"common/ui/loading_btn","currentComponent":_vm.composant_loading_btn,"slot_content":"Bouton class : vert_inv","params":{
                class: 'inline vert_inv'
			},"notes":"Ajouter la classe 'inline' pour que le bouton s'ajuste à son contenu"}}),_c('Show_Component',{attrs:{"path":"common/ui/loading_btn","currentComponent":_vm.composant_loading_btn,"slot_content":"Bouton class : blancgris","params":{
                class: 'inline blancgris'
			},"notes":"Ajouter la classe 'inline' pour que le bouton s'ajuste à son contenu"}}),_c('Show_Component',{attrs:{"path":"common/ui/loading_btn","currentComponent":_vm.composant_loading_btn,"slot_content":"Bouton class : grisblanc","params":{
                class: 'inline grisblanc'
			},"notes":"Ajouter la classe 'inline' pour que le bouton s'ajuste à son contenu"}}),_c('Show_Component',{attrs:{"path":"common/ui/loading_btn","currentComponent":_vm.composant_loading_btn,"slot_content":"Bouton class : grisblanc fondfonce","params":{
                class: 'inline grisblanc fondfonce'
			},"notes":"Ajouter la classe 'inline' pour que le bouton s'ajuste à son contenu"}}),_c('Show_Component',{attrs:{"path":"common/ui/loading_btn","currentComponent":_vm.composant_loading_btn,"slot_content":"Bouton class : parme","params":{
                class: 'inline parme'
			},"notes":"Ajouter la classe 'inline' pour que le bouton s'ajuste à son contenu"}}),_c('Show_Component',{attrs:{"path":"common/ui/loading_btn","currentComponent":_vm.composant_loading_btn,"slot_content":"Bouton class : right","params":{
                class: 'right'
			},"notes":"Ajouter la classe 'right' pour que le bouton soit à droite"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }