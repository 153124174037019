import Show_Component from '../show_component.vue'
import Loading_btn from '@/common/ui/loading_btn.vue'

export default {
	name: 'Buttons',
	data(){
		return {
			composant_loading_btn: null
		}
	},
	mounted(){
		this.loadComponents()
	},
	methods: {
		async loadComponents(){
            const component = await import('@/common/ui/loading_btn.vue');
            this.composant_loading_btn = component.default;

        }
	},
	components: {
		Show_Component,
		Loading_btn
	},
}
