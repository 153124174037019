import _ from 'lodash'
import FiltreHeader from '../filtres/_filtre_header.vue';


export default {
	data() {
		return {
			componentsList: [
				{
					title: 'Onglets / Sections',
					code: 'onglets'
				},
				{
					title: 'Conteneurs',
					code: 'containers'
				},
				{
					title: 'Menus',
					code: 'menus'
				},
				{
					title: 'Champs',
					code: 'fields'
				},
				{
					title: 'Boutons',
					code: 'buttons'
				},
				{
					title: 'Structure',
					code: 'content_structure'
				},
				{
					title: 'Icons',
					code: 'icons'
				},
			]
		}
	},
	created(){
	},
	methods: {
		setPage(page){
			this.$store.commit('UI_SET_UI_PAGE', page);
		}
	},
	computed:{
		
	},
	components: {
		FiltreHeader
	}
}