import Show_Component from '../show_component.vue'
import Card from '../../../common/ui/card.vue' 
import Modale from '../../../common/ui/modale.vue'

export default {
	name: 'Containers',
	data(){
		return {
			composant_card: null,
			composant_modale: null
		}
	},
	mounted(){
		this.loadComponents()
	},  
	methods: {
		async loadComponents(){
            const component = await import('@/common/ui/card.vue');
            this.composant_card = component.default;
			
			const component_modale = await import('@/common/ui/modale.vue');
			this.composant_modale = component_modale.default;
        },
        affiche(content){
          this.$dialog.alert(content)
        }
	},
	components: {
		Card,
		Show_Component,
		Modale
	}
}