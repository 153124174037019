import _ from 'lodash'
import ContentEditable from '../../common/forms/ContentEditable.vue'
import LoadingBtn from '../../common/ui/loading_btn.vue'

export default {
	name: 'show_component',
    data(){
        return {
            vmodel: null,
            config: {},
            show_details: false,
            switch_component: false
        }
    },
	props: {
        currentComponent: {},
		styles: {
			default: ''
		},
        path: {
            type: String,
            required: true
        },
		params: {
			type: Object,
			required: false
		},
		notes: {
			type: String,
			required: false
		},
        slot_content: {
            type: String,
            required: false
        },
        btn_switch: {
            type: Boolean,
            required: false,
            default: false
        },
        btn_switch_label: {
            type: String,
            required: false,
            default: 'Switch'
        }
	},
    created(){
        // Transfère les datas de params sur les data locales
        this.config = _.cloneDeep(this.params)

        // Cas du vmodel
        if(this.params?.vmodel){
            this.vmodel = _.cloneDeep(this.params.vmodel)
            delete this.config.vmodel
        }
    },
    mounted(){
    },
    methods: {
        findParam(label){
            return _.find(this.config_list, {label: label})
        },
        getContentForEdition(type, value){
            if(['string', 'number', 'boolean'].includes(type)){
                return value
            }
            return JSON.stringify(value, 'any', 4)
        },
        updateParam(label, value){
            let config = _.cloneDeep(this.config)
            switch(this.findParam(label)?.type){
                case 'array':
                    config[label] = JSON.parse(value)
                    break
                case 'object':
                    config[label] = JSON.parse(value)
                    break
                case 'boolean':
                    config[label] = value === 'true'
                    break
                case 'number':
                    config[label] = Number(value)
                    break
                default:
                    config[label] = value
                    break
            }
            this.config = config
        },
        switchOffComponent(){
            this.switch_component = false
        }
    },
    computed: {
        config_list(){
            let list = []
            
            if(this.currentComponent?.props){
                let props = _.cloneDeep(this.currentComponent.props)
                props['class'] = {type: String}
                _.forEach(props, (value, key) => {
                    if(key != 'value' && !_.find(list, {label: key})){
                        let type = value.type
                        Array.isArray(value.type) ? type = value.type[0] : type
                        list.push({
                            label: key,
                            type: type?.name?.toLowerCase() || 'à préciser dans les props'
                        })
                    }
                })
            }

            // Ordonne par label
            list = _.sortBy(list, 'label')

            return list
        },
        getEmits(){
            if(!this.currentComponent?.emits) return null
            return _.join(this.currentComponent.emits, "\n")
        },
        getNotes(){
            if(!this.notes) return null
            let notes = this.notes.replace(/</g, '&lt;').replace(/>/g, '&gt;')
            // supprime tous les espaces et les tabs en début de ligne
            notes = notes.replace(/^[\s\t]+/gm, '')
            return notes
        }
    },
    components: {
        ContentEditable,
        LoadingBtn
    }
}