import Filter_ui from './filter_ui.vue'
import Page_containers from './pages/containers.vue'
import Page_onglets from './pages/onglets.vue'
import Page_fields from './pages/fields.vue'
import Page_content_structure from './pages/content_structure.vue'
import Page_menus from './pages/menus.vue'
import Page_icons from './pages/icons.vue'
import Page_buttons from './pages/buttons.vue'

export default {
	methods: {
	},
	components: {
		Filter_ui,
		Page_onglets,
		Page_containers,
		Page_fields,
		Page_content_structure,
		Page_menus,
		Page_icons,
		Page_buttons
	}
}